import user from "./redux/user";
import loading from "./redux/loading";
import history from "./redux/history";
import messages from "./redux/messages";

import { combineReducers } from "redux";


export default combineReducers ({
    forecastuser: user,
    forecastloading: loading,
    forecasthistory: history,
    forecastmessages: messages
});
