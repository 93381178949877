import React, { useEffect, useReducer, useRef, memo, forwardRef, useImperativeHandle, Fragment, useState} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setLoading } from "../redux/loading";
import { useDispatch, useSelector } from "react-redux";
import { request } from "../../../helpers/requests";
import TextField from "@mui/material/TextField";
import { Box, Button, InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import { createSlice } from "@reduxjs/toolkit";
import { Sun } from "../assets";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";



let messagesSlice = createSlice({
  name: 'forecastmessages',
  initialState: {
      prompt: '',
      content: '',
      _id: null,
      latest: {
          prompt: '',
          content: ''
      },
      all: []
  },
  reducers: {
      clearPrompt: (state) => {
          return {
              ...state,
              prompt: '',
          }},
      emptyAllRes: () => {
          return {
              prompt: '',
              content: '',
              _id: null,
              latest: {
                  prompt: '',
                  content: ''
              },
              all: []
          }
      },
      addList: (state, { payload }) => {
          const { _id, items } = payload
          state._id = _id
          state.all = items
          return state
      },
      insertNew: (state, { payload }) => {
          const { chatsId, content = null,
              resume = false, fullContent = null,
              _id = null, prompt = null } = payload

          if (_id) {
              state._id = _id
          }

          state.latest.id = chatsId

          if (prompt) {
              state.latest.prompt = prompt
          }

          const addToList = (latest) => {
              if (state['all'].find(obj => obj.id === latest.id)) {
                  state['all'].forEach(obj => {
                      if (obj.id === latest.id) {
                          obj.content = latest.content
                      }
                  })
              } else {
                  state['all'].push(latest)
              }
          }

          if (content && resume) {
              state.latest.content += content
              addToList(state.latest)

          } else if (content) {
              state.latest.content = content
              addToList(state.latest)
          }

          if (fullContent) {
              state.content = fullContent
          }

          return state
      },
      livePrompt: (state, { payload }) => {
          state.prompt = payload
          return state
      }
  }
})

export const { clearPrompt, emptyAllRes, insertNew, livePrompt, addList } = messagesSlice.actions

const reducer = (state, { type, status }) => {
  switch (type) {
    case "chat":
      return {
        chat: status,
        loading: status,
        resume: status,
        actionBtns: false,
      };
    case "error":
      return {
        chat: true,
        error: status,
        resume: state.resume,
        loading: state.loading,
        actionBtns: state.actionBtns,
      };
    case "resume":
      return {
        chat: true,
        resume: status,
        loading: status,
        actionBtns: true,
      };
    default:
      return state;
  }
};


const New = memo(() => {
  const dispatch = useDispatch()
  return (
    <Box id="inputContainer" align="center" justify="center" sx={{}}>
      <div>
        <h1 className='title currentColor'>AI Forecast</h1>
      </div>

      <div className="flex">
        <div className='inner'>
          <div className='card'>
            <Sun />
            <h4 className='currentColor'>Create an SEO forecast powered by SEO data and AI</h4>
          </div>
        </div>
      </div>
    </Box>
  )
})





const Forecast = forwardRef(({ error }, ref) => {

  const contentRef = useRef();

  const { forecastmessages } = useSelector((state) => state.aiforecast);
  const { latest, content, all } = forecastmessages;

  const loadResponse = (stateAction, response = content, chatsId = latest?.id) => {
    clearInterval(window.interval);
    stateAction({ type: "resume", status: true });
    contentRef?.current?.classList?.add("blink");
  
    let index = 0;
    const appendText = () => {
      if (index < response.length && contentRef?.current) {
        contentRef.current.innerHTML = response.slice(0, index + 1);
        contentRef.current.scrollTop = contentRef.current.scrollHeight; // Keep the view scrolled down
        index++;
      } else {
        clearInterval(window.interval);
      }
    };
  
    window.interval = setInterval(appendText, 20); // Keep the interval to animate text appearing
  };


  useImperativeHandle(ref, () => ({
    loadResponse,
    clearResponse: () => {
      if (contentRef?.current) {
        contentRef.current.innerHTML = "";
        // contentRef?.current?.classList.add("blink");
      }
    },
  }));

  const CodeBlock = ({ code }) => {
    return <pre><code>{code}</code></pre>;
  };
  
  const formatCodeBlocks = (text) => {
    const codeBlockRegex = /```(.*?)```/gs;
    return text.split(codeBlockRegex).map((part, index) => {
      // Odd indices are code, even are normal text
      return index % 2 === 1 ? <CodeBlock key={index} code={part} /> : part;
    });
  };
  
  const formatSequentialNumbers = (text) => {
    return text.replace(/(\d+)\.(\d+)\./g, (match, num1, num2) => (
      <React.Fragment key={match}>
        <br /> {/* Add a <br /> before each number */}
        <span className="sequential-number">
          {num1}.{num2}.
        </span>
      </React.Fragment>
    ));
  };

  const AutoFormatText = ({ text }) => {
    const [formattedText, setFormattedText] = useState([]);
  
    useEffect(() => {
      const formatText = () => {
        // First, split and format code blocks
        let fragments = formatCodeBlocks(text);
        // Then, format sequential numbers within each non-code fragment
        fragments = fragments.map((fragment, index) => {
          return typeof fragment === 'string' ? formatSequentialNumbers(fragment) : fragment;
        });
        setFormattedText(fragments);
      };
  
      formatText();
    }, [text]); // Only re-run the effect if new text is provided
  
    return (
      <Typography sx={{ whiteSpace: "pre-line" }}>
        {formattedText}
      </Typography>
    );
  };

  // Define common styles
const userPromptStyle = {
  textAlign: "left",
  paddingTop: "55px",
  paddingLeft: "35px",
  paddingBottom: "80px",
  // overflow: "hidden",
  maxWidth: "100%", /* Prevent overflow beyond the parent container */
  wordWrap: "break-word" /* Ensure long words don't cause container to expand */
};

const iconStyle = {
  marginTop: "-40px",
  // display: "flex",
  textAlign: "left",
  left: "-10px",
  // position: "relative",
};

const textStyle = {
  // display: "flex",
  textAlign: "left",
};

const chatResponseStyle = {
  ...userPromptStyle,
  paddingTop: "55px",
  paddingLeft: "35px",
  paddingBottom: "80px",
  maxWidth: "100%",
  wordWrap: "break-word",
  backgroundColor: "rgba(101, 101, 101, 0.06)",
  height: "auto",
  minHeight: "100px", /* Stabilizes initial size */
  maxHeight: "400px",
  overflowY: "auto",
  transition: "height 0.2s ease" /* Only animate height changes */
};

const accountCircleStyle = {
  height: "41px",
  width: "41px",
};

// Create smaller component for repeated JSX structures
const UserPrompt = ({ icon, text, error, contentRef }) => (
  <Box className="userPrompt" sx={userPromptStyle}>
    <Box className="icon" sx={iconStyle}>
      {icon}
    </Box>
    <Box className="txt" sx={textStyle}>
      {error ? (
        <Box className="error">Something went wrong.</Box>
      ) : (
        <AutoFormatText text={text} contentRef={contentRef} />
      )}
    </Box>
  </Box>
);


  return (
    <Box className="Chat" sx={{ 
      height: "100%", marginBottom: "170px"
       }}>
      {all?.filter(obj => !obj.id || obj.id !== latest?.id)?.map((obj, key) => (
        <Fragment key={key}>
          <UserPrompt icon={<AccountCircle sx={accountCircleStyle} />} text={obj?.prompt} />
          <Box className="chatResponse" sx={chatResponseStyle}>
            <AutoFormatText text={obj?.content} />
          </Box>
        </Fragment>
      ))}

      {latest?.prompt?.length > 0 && (
        <Fragment>
          <UserPrompt icon={<AccountCircle sx={accountCircleStyle} />} text={latest?.prompt} />
          <Box className="chatResponse" sx={chatResponseStyle}>
            <span ref={contentRef} />
          </Box>
        </Fragment>
      )}
    </Box>
  );
});




const Main = () => {
  let location = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const forecastRef = useRef();

  const { forecastuser } = useSelector((state) => state.aiforecast);

  const { id = null } = useParams();

  const [status, stateAction] = useReducer(reducer, {
    chat: false,
    error: false,
    actionBtns: false,
  });



  

  useEffect(() => {
    if (forecastuser) {
      dispatch(emptyAllRes());
      setTimeout(() => {
        if (id) {
          const getSaved = async () => {
            let res = null;
            try {
              res = await request("post", "/aiforecast/saved", {
                chatId: id,
              });
            } catch (err) {
              console.log(err);
              if (err?.response?.data?.status === 404) {
                navigate("/404");
              } else {
                alert(err);
                // dispatch(setLoading(false));
              }
            } finally {
              if (res?.data) {
                dispatch(addList({ _id: id, items: res?.data?.data }));
                stateAction({ type: "resume", status: false });
                dispatch(setLoading(false));
              }
            }
          };

          getSaved();
        } else {
          stateAction({ type: "chat", status: false });
          dispatch(setLoading(false));
        }
      }, 1000);
    }
  }, [location]);

  return (
    <Box id="chatPageContainer" align="center" justify="center" sx={{}}>
    
    {status.chat ? 
      
      <Box id="chatPageContainer" align="center" justify="center" sx={{}}>
        <Box
          id="currentChat"
          sx={{
            maxWidth: "1400px",
            marginLeft: "-50px",
            position: "absolute",
            display: "block",
          }}
        >
        {/* Forecast - The area where the response is displayed */}
        <Forecast ref={forecastRef} error={status.error} /> 
        </Box>
        </Box>
        
        : 
        
        <New />
        
      }
      
      <Box id="inputContainer" align="center" justify="center" sx={{}}>
        <InputArea
          status={status}
          forecastRef={forecastRef}
          stateAction={stateAction}
        />
      </Box>
    </Box>
  );
};

export default Main;


//Input Area
const InputArea = ({ status, forecastRef, stateAction }) => {
  let textAreaRef = useRef();
 let dispatch = useDispatch();
  const { prompt, content, _id } = useSelector((state) => state.aiforecast.forecastmessages);

  const FormHandle = async () => {
    if (prompt?.length > 0) {
      stateAction({ type: "chat", status: true });
  
      let chatsId = Date.now();
  
      dispatch(insertNew({ id: chatsId, content: "", prompt }));
      forecastRef?.current?.clearResponse();
  
      // Clear the prompt right after sending the message
      dispatch(clearPrompt());
  
      let res = null;
  
      try {
        if (_id) {
          res = await request("put", "/aiforecast/forecast", {
            chatId: _id,
            prompt,
          });
        } else {
          res = await request("post", "/aiforecast/forecast", {
            prompt,
          });
        }
      } catch (err) {
        console.log(err);
        if (err?.response?.data?.status === 405) {
          dispatch(emptyAllRes());
        } else {
          stateAction({ type: "error", status: true });
        }
      } finally {
        if (res?.data) {
          const { _id, content } = res?.data?.data;
  
          dispatch(insertNew({ _id, fullContent: content, chatsId }));
  
          forecastRef?.current?.loadResponse(stateAction, content, chatsId);
          // The clearPrompt action is already dispatched above, so we remove it from here
          stateAction({ type: "error", status: false });
        }
      }
    }
  };

  return (
    <Box id="inputContainer" align="center" justify="center" sx={{}}>
            <TextField
              id="forecastTextArea"
              label="Enter your website"
              ref={textAreaRef}
              variant="outlined"
              multiline
              maxRows={6}
              value={prompt}
              onChange={(e) => {
                dispatch(livePrompt(e.target.value));
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  // checking if enter was pressed and shift wasn't held down
                  e.preventDefault(); // prevent the newline character from being entered
                  FormHandle(); // call the FormHandle function
                }
              }}
              sx={{
                maxWidth: "1400px",
                bottom: "300px",
                right: "200px",
                left: "200px",
                position: "fixed",
                alignItems: "stretch",
                display: "grid",
                "& .MuiFormControl-root": {
                  backgroundColor: "#fff",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!status?.loading ? (
                      <IconButton onClick={FormHandle}>
                        {<SendIcon />}
                      </IconButton>
                    ) : (
                      <Box className="loading">
                        <div className="dot" />
                        <div className="dot-2 dot" />
                        <div className="dot-3 dot" />
                      </Box>
                    )}
                  </InputAdornment>
                ),
              }}
            />

        {status.error ? (
          <Box className="error">
            <p>There was an error generating a forecast</p>
            <Button onClick={FormHandle}>
              Regenerate forecast
            </Button>
          </Box>
            ) : <></>
        }
    </Box>
  );
};
