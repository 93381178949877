import { createSlice } from "@reduxjs/toolkit";

let forecastloadingSlice = createSlice({
    name: 'forecastloading',
    initialState: true,
    reducers: ({
        setLoading: (state, { payload }) => {
            state = payload
            return state
        }
    })
})

export const { setLoading } = forecastloadingSlice.actions

export default forecastloadingSlice.reducer

