import { createSlice } from '@reduxjs/toolkit'

let forecastuserSlice = createSlice({
    name: 'forecastuser',
    initialState: {name: ''},
    // initialState: null,
    reducers: {
        insertUser: (state, action) => {
            return action.payload
        },
        emptyUser: (state, action) => {
            return null
        }
    }
})

export const { insertUser, emptyUser } = forecastuserSlice.actions

export default forecastuserSlice.reducer

