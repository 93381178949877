import React, { useEffect, useState, useContext, memo } from "react";
import { styled, useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import AppMenuItemForecast from "./AppMenuItemForecast";
import Button from "@mui/material/Button";
import Drawer from '@mui/material/Drawer';

const drawerWidth = 240;


// function MenuDrawer({router, allowedRoutes}) {
function ForecastMenu({ forecasthistory }) {

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  useEffect(() => {
  }, []);




  const list = (anchor) => (
      <Box
          sx={{ width: 250, }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
      >
        <List
            id="verticalChatList"
            // onMouseEnter={() => handleChatDrawerOpen()}
            // onMouseLeave={() => handleChatDrawerClose()}
            sx={{
              // marginLeft: "-20px", // filter: "blur(4px)",
              height: "100%", // backdropFilter: "blur(10px)",
              width : "100%"
            }}
        >
          {forecasthistory.map((item, index) => (
              <AppMenuItemForecast {...item} key={index} />
          ))}
        </List>
      </Box>
  );



  return (
      <>
        {['Forecasts'].map((anchor) => (
            <React.Fragment key={anchor}>
              <Button variant="outlined" onClick={toggleDrawer(anchor, true)}
                      sx={{
                        width: "45",
                          height: "45px",
                        marginLeft: ".5rem",
                        marginBottom: "5px",
                        // paddingRight: ".75rem",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        position: "relative",
                        // color: "rgb(0, 0, 0)",
                        // width: "100%",
                        transition: "all .2s ease-in-out",
                        // border: "none",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                        // paddingLeft: "3em",
                        borderRadius: ".375rem",
                        background: "transparent",
                        //text-align: left;
                        fontWeight: "normal",
                        fontSize: ".875rem",
                        lineHeight: "1.25rem",
                        textTransform: "unset",
                        flex: "none",
                        display: "flex",
                      }}
              >{anchor}</Button>
              <Drawer
                  sx={{zIndex:"9999999",}}
                  // anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
              >
                {list('left')}
              </Drawer>
            </React.Fragment>
        ))}
      </>

  );
}

export default ForecastMenu;
