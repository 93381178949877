import React, {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import List from '@mui/material/List';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import AppMenuItemComponent from './AppMenuItemComponent'
import {ListItemButton, Tooltip} from "@mui/material";
import Fade from '@mui/material/Fade'
import {Message} from "../OpenAI/assets";


const AppMenuItemComponent = ({element, onClick, path, children}) => {
    if (!element) {
        return (
                <ListItemButton
                    id={path}
                    children={children}
                    onClick={onClick}
                />
        )
    }

    // If path, return a ListItem with a path component
    return (
            <ListItemButton
                children={children}
                component={Link}
                to={`${path}`}
            />
    )
}





const AppMenuItemForecast =  (history) => {
    // const isExpandable = children && children.length > 0

    const navigate = useNavigate();

    const MenuItem = (
        /*If there's no menu icon, then it is assumed the user accesses the route not from the left hand menu*/
        <AppMenuItemComponent MenuToolTip={history.chatId} onClick={() => {navigate(`/forecast/${history?.chatId}`);
                                          }}
        >
            {/* Display an icon if any */}
                <Tooltip title={history.chatId}
                         arrow
                         enterDelay={1000}
                         leaveDelay={200}
                         TransitionComponent={Fade}
                         TransitionProps={{timeout: 600}}
                >
                    <ListItemButton
                        sx={{"&:hover": {backgroundColor: "rgba(0,0,0,0)"},
                                "&:active": {backgroundColor: "rgba(0,0,0,0)"}
                        }}
                        dense>
                        <ListItemIcon> <Message/> </ListItemIcon> <ListItemText primary={history.prompt.slice(0,20)}/>
                    </ListItemButton>
                </Tooltip>
        </AppMenuItemComponent>

    )


    // const MenuItemChildren = isExpandable ? (
    //     <Collapse in={openCollapse} timeout="auto" unmountOnExit>
    //         <List disablePadding>
    //             {/*{children.map((item, index) => (<AppMenuItemChat {...item} key={index}/>))}*/}
    //             <Divider/>
    //         </List>
    //     </Collapse>
    // ) : null



    return (
        <>
            {MenuItem}
            {/*{MenuItemChildren}*/}
        </>
    )
}


export default AppMenuItemForecast