import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { activePage, addHistory } from "../../redux/history";
import { request } from "../../../../helpers/requests";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { Check, Delete, Add } from "@mui/icons-material";
import ForecastMenu from "../../../MenuDrawer/ForecastMenu";


const Menu = () => {
  let path = window.location.pathname;

  const menuRef = useRef(null);
  const btnRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { forecasthistory } = useSelector((state) => state.aiforecast);
  const [confirm, setConfim] = useState(false);



  const clearHistory = async (del) => {
    if (del) {
      let res = null;

      try {
        res = request("delete", "/aiforecast/all");
      } catch (err) {
        alert("Error");
        console.log(err);
      } finally {
        if (res) {
          navigate("/");
          dispatch(addHistory([]));
        }

        setConfim(false);
      }
    } else {
      setConfim(true);
    }
  };


  //Menu

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        !menuRef?.current?.contains(e.target) &&
        !btnRef?.current?.contains(e.target)
      ) {
        menuRef?.current?.classList?.remove("showMd");
        document.body.style.overflowY = "auto";
      }
    });
  });


  // History Get
  useEffect(() => {
    const getHistory = async () => {
      let res = null;
      try {
        res = await request("get", "/aiforecast/history");
        console.log(`res for history: `, res)
      } catch (err) {
        console.log(err);
      } finally {
        if (res?.data) {
          dispatch(addHistory(res?.data?.data));
        }
      }
    };

    getHistory();
  }, [path]);

  
  // History active
  useEffect(() => {
    setConfim(false);
    let chatId = path.replace("/forecast/", "");
    chatId = chatId.replace("/", "");
    dispatch(activePage(chatId));
  }, [path, forecasthistory]);



  return (
    <Box
      id="chatMenuButtons"
      align="center"
      justify="center"
      sx={{
        display: "flex",
        justifyContent: "center",
        marginLeft: "-100px"
      }}
    >
      <Box
        ref={menuRef}
        sx={{
          marginLeft:"-15px",
          position: "fixed",
          zIndex: "2000",
          maxWidth: "260px",
          width: "100px",
          height: "40px",
          display: "flex",
          flexDirection: "row",
          paddingTop: "60px",
          marginTop: "-100px",
        }}
      >
        <Box>
          <Button
            variant="outlined"
            type="button"
            aria-label="new"
            onClick={() => {
              if (path.includes("/forecast")) {
                navigate("/");
                window.location.reload();
              } else {
                navigate("/");
                window.location.reload();
              }
            }}
            sx={{
              width: "60px",
              marginBottom: "5px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              position: "relative",
              transition: "all .2s ease-in-out",
              paddingTop: ".75rem",
              paddingBottom: ".75rem",
              borderRadius: ".375rem",
              background: "transparent",
              fontWeight: "normal",
              fontSize: ".875rem",
              lineHeight: "1.25rem",
              textTransform: "unset",
              flex: "none",
              display: "flex",
            }}
            startIcon={<Add sx={{ 
              marginLeft: "15px" 
            }} />}
          >

            <Typography
              sx={{
                paddingTop: "3px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                justifyContent: "flex-start",
                flex: "auto",
                display: "flex",
              }}
            >

            </Typography>
          </Button>
        </Box>

        <ForecastMenu forecasthistory={forecasthistory} />

        {/* clear history */}
        <Box>
          {forecasthistory?.length > 0 && (
            <>
              {confirm ? (
                <>
                  <Button
                    variant="outlined"
                    sx={{
                      width: "140px",
                      height: "45px",
                      marginBottom: "5px",
                      borderColor: "rgb(209,10,10)",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      position: "relative",
                      color: "rgb(209,10,10)",
                      transition: "all .2s ease-in-out",
                      paddingTop: ".75rem",
                      paddingBottom: ".75rem",
                      borderRadius: ".375rem",
                      background: "transparent",
                      fontWeight: "normal",
                      fontSize: ".875rem",
                      lineHeight: "1.25rem",
                      textTransform: "unset",
                      flex: "none",
                      display: "flex",
                    }}
                    onClick={() => window.location.reload(false)}>
                    <Typography
                      sx={{
                        paddingTop: "3px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        justifyContent: "flex-start",
                        flex: "auto",
                        display: "flex",
                      }}
                    >Dismiss
                    </Typography>
                  </Button>
                  <Button
                    sx={{
                      width: "140px",
                      height: "45px",
                      borderColor: "rgb(209,10,10)",
                      marginBottom: "5px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      position: "relative",
                      color: "rgb(209,10,10)",
                      transition: "all .2s ease-in-out",
                      paddingTop: ".75rem",
                      paddingBottom: ".75rem",
                      borderRadius: ".375rem",
                      background: "transparent",
                      fontWeight: "normal",
                      fontSize: ".875rem",
                      lineHeight: "1.25rem",
                      textTransform: "unset",
                      flex: "none",
                      display: "flex",
                    }}
                    variant="outlined"
                    onClick={() => clearHistory(true)}
                    startIcon={<Check />}
                  >
                    <Typography
                      sx={{
                        paddingTop: "3px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        justifyContent: "flex-start",
                        flex: "auto",
                        display: "flex",
                      }}
                    >Delete all your generated forecasts
                    </Typography>
                  </Button>
                </>
              ) : (
                <Button
                  variant="outlined"
                  sx={{
                    width: "45px",
                    height: "45px",
                    marginLeft: ".25rem",
                    marginBottom: "5px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    position: "relative",
                    transition: "all .2s ease-in-out",
                    paddingTop: ".75rem",
                    paddingBottom: ".75rem",
                    borderRadius: ".375rem",
                    background: "transparent",
                    fontWeight: "normal",
                    fontSize: ".875rem",
                    lineHeight: "1.25rem",
                    textTransform: "unset",
                    flex: "none",
                    display: "flex",
                  }}
                  onClick={() => clearHistory(false)}
                  startIcon={<Delete sx={{ marginLeft: "15px" }} />}
                >
                  <Typography
                    sx={{
                      paddingTop: "3px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      justifyContent: "flex-start",
                      flex: "auto",
                      display: "flex",
                    }}
                  >
                  </Typography>
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Menu;
