import Roles from './Roles'
import {RouteObject} from "react-router-dom";

import Dashboard from "../modules/Dashboard";
// import Salesforce from '../modules/Salesforce/index';
// import NewCases from '../modules/Salesforce/NewCases';
// import OpenCases from '../modules/Salesforce/OpenCases';
// import CasesByAccount from '../modules/Salesforce/Case/CasesByAccount';
// import SalesforceToken from '../modules/Salesforce/SalesforceToken';
import Admin from '../modules/Admin';
// import User from '../modules/User';
// import Client from '../modules/Client';
import Room from '../modules/Room';
// import ClientGoogleMap from '../modules/ClientGoogleMap';
// import Staging from '../modules/Staging/index.js';
// import Launch from '../modules/Launch';
// import NationState from '../modules/NationState'
import ClientSite from "../modules/ClientSite";
// import Changelog from "../modules/Changelog";

/*
* Material Icons
* */
import WebAssetIcon from '@mui/icons-material/WebAsset';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import WebIcon from '@mui/icons-material/Web';
// import ConstructionIcon from '@mui/icons-material/Construction';
// import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';
// import MapIcon from '@mui/icons-material/Map';
// import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
// import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
// import CasesIcon from '@mui/icons-material/Cases';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
// import Divider from '@mui/material/Divider';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LeadGenerator from "../modules/LeadGenerator";
import Login from "../modules/Login";
import Staging from "../modules/Staging";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ManualStaging from "../modules/Staging/ManualStaging";
import ConstructionIcon from "@mui/icons-material/Construction";
import StagingAutomation from "../modules/Staging/StagingAutomation";
import MotionPhotosAutoIcon from "@mui/icons-material/MotionPhotosAuto";
import User from "../modules/User";
import ContainerSiteStaging from "../modules/Staging/ContainerSiteStaging";
import Domain from "../modules/Domains";
import Launch from "../modules/Launch";
import ManualLaunching from "../modules/Launch/ManualLaunching";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import Users from "../modules/Admin/Users";
import MapIcon from "@mui/icons-material/Map";
import DatabaseSettings from "../modules/Admin/DatabaseSettings";
import StorageIcon from "@mui/icons-material/Storage";
import ClientSiteKeyConfiguration from "../modules/Admin/ClientSiteKeyConfiguration";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import OpenAI from "../modules/OpenAI";
import AIForecast from "../modules/AIForecast";
import ClientCases from "../modules/ClientCases";
// import LeaderboardIcon from '@mui/icons-material/Leaderboard';
/* Saved icons for future use */
// import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';


/*If there's no menu icon, then it is assumed the user accesses the route not from the left hand menu*/
const NavigationRoutePermissions = [
    {
        element: Login,
        path: '/',
        name: 'Login',
        MenuIcon: DashboardIcon,
        MenuToolTip: "Login",
        permission: [
            Roles.GUEST,
            // Roles.SUPER_ADMIN,
            // Roles.ADMIN,
            // Roles.ACCOUNT_MANAGER,
            // Roles.SALES_MANAGER,
            // Roles.SALES_IMC,
        ],
    },
    // {
    //     element: Dashboard,
    //     // path: '/',
    //     path: '/dashboard',
    //     name: 'Bullpen',
    //     MenuIcon: DashboardIcon,
    //     MenuToolTip: "Live department data",
    //     permission: [
    //         Roles.SUPER_ADMIN,
    //         Roles.ADMIN,
    //         Roles.ACCOUNT_MANAGER,
    //         Roles.SALES_MANAGER,
    //         Roles.SALES_IMC,
    //     ],
    // },
    {
        element: AIForecast,
        // path: '/',
        path: '/aiforecast',
        name: 'AI Forecast',
        MenuIcon: DashboardIcon,
        MenuToolTip: "AI Forecast",
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.ACCOUNT_MANAGER,
            Roles.SALES_MANAGER,
            Roles.SALES_IMC,
        ],
    },
    {
        element: Domain,
        // path: '/',
        path: '/domains',
        name: 'Domains',
        MenuIcon: WebAssetIcon,
        MenuToolTip: "Register a domain",
        permission: [
            Roles.SUPER_ADMIN,
            // Roles.ADMIN,
            // Roles.ACCOUNT_MANAGER,
            // Roles.SALES_MANAGER,
            // Roles.SALES_IMC,
        ],
    },
    {
        element: OpenAI,
        // path: '/',
        path: '/',
        name: 'ChatGPT',
        MenuIcon: AutoAwesomeIcon,
        MenuToolTip: "Premium ChatGPT",
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.ACCOUNT_MANAGER,
            Roles.SALES_MANAGER,
            Roles.SALES_IMC,
        ],
    },
    {
        element: OpenAI,
        // path: '/',
        path: '/chat/:id',
        name: 'V Chat-GPT',
        // MenuIcon: AutoAwesomeIcon,
        MenuToolTip: "Premium Chat-GPT",
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.ACCOUNT_MANAGER,
            Roles.SALES_MANAGER,
            Roles.SALES_IMC,
        ],
    },
    {
        name: 'Site Ops',
        MenuIcon: WebIcon,
        path: '/clients',
        MenuToolTip: "Site Ops",
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.ACCOUNT_MANAGER
        ],
        children: [
            {
                element: <ClientSite/>,
                path: '/clients',
                name: 'Site List',
                MenuIcon: WysiwygIcon,
                MenuToolTip: "All Sites on server",
                permission: [
                    Roles.SUPER_ADMIN,
                    Roles.ADMIN,
                    Roles.ACCOUNT_MANAGER
                    // Roles.SALES_MANAGER
                ],
            },
            {
                element: <ClientCases/>,
                path: '/clients/cases',
                name: 'Cases',
                MenuIcon: WysiwygIcon,
                MenuToolTip: "All Open Cases",
                permission: [
                    Roles.SUPER_ADMIN,
                    // Roles.ADMIN,
                    // Roles.ACCOUNT_MANAGER
                    // Roles.SALES_MANAGER
                ],
            },
            {
                element: <ManualStaging/>,
                // index: true,
                path: '/clients/manualstaging',
                name: 'Stage a Site',
                MenuIcon: ConstructionIcon,
                MenuToolTip: "Stage a site based on a Salesforce Account",
                permission: [
                    Roles.SUPER_ADMIN,
                    Roles.ADMIN,
                    Roles.ACCOUNT_MANAGER
                    // Roles.SALES_MANAGER
                ],
            },
            {
                element: <Staging/>,
                path: '/clients/staging',
                name: 'Staging Progress',
                MenuIcon: ListAltIcon,
                MenuToolTip: "Sites recently being staged",
                permission: [
                    Roles.SUPER_ADMIN,
                    Roles.ADMIN,
                    Roles.ACCOUNT_MANAGER
                ],
            },
            {
                element: <StagingAutomation/>,
                path: '/clients/staging/stagingautomation',
                name: 'Staging Automation',
                MenuIcon: MotionPhotosAutoIcon,
                MenuToolTip: "Staging Automation ",
                permission: [
                    Roles.SUPER_ADMIN,
                    // Roles.ADMIN,
                    // Roles.ACCOUNT_MANAGER
                    // Roles.SALES_MANAGER
                ],
            },
            // {
            //     element: <ContainerSiteStaging/>,
            //     path: '/clients/staging/containersitestaging',
            //     name: '(Alpha) Container Staging',
            //     MenuIcon: MotionPhotosAutoIcon,
            //     MenuToolTip: "Alpha version of container staging",
            //     permission: [
            //         Roles.SUPER_ADMIN,
            //         // Roles.ADMIN,
            //         // Roles.ACCOUNT_MANAGER
            //         // Roles.SALES_MANAGER
            //     ],
            // },
            {
                element: <Launch/>,
                path: '/clients/launch',
                name: 'Launch Progress',
                MenuIcon: AssignmentIcon,
                MenuToolTip: "Sites recently launched",
                permission: [
                    Roles.SUPER_ADMIN,
                    Roles.ADMIN,
                    Roles.ACCOUNT_MANAGER
                ],
            },
            {
                element: <ManualLaunching/>,
                path: '/clients/launch/manuallaunching',
                name: 'Launch a Site',
                MenuIcon: RocketLaunchIcon,
                MenuToolTip: "Launch a Site",
                permission: [
                    Roles.SUPER_ADMIN,
                    Roles.ADMIN,
                    Roles.ACCOUNT_MANAGER
                    // Roles.SALES_MANAGER
                ],
            },
        ]
    },
    {
        element: User,
        path: '/user',
        // name: 'User',
        // MenuIcon: ConstructionIcon,
        // MenuToolTip: "Staging Area",
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.ACCOUNT_MANAGER
        ],
    },
    // {
    //     element: Launch,
    //     path: '/clients/launch',
    //     name: 'Launch Pad',
    //     MenuIcon: FlightTakeoffIcon,
    //     MenuToolTip: "Launch",
    //     permission: [
    //         Roles.SUPER_ADMIN,
    //         Roles.ADMIN,
    //         Roles.ACCOUNT_MANAGER
    //     ]
    // },
    // {
    //     element: ClientGoogleMap,
    //     path: '/clientgooglemaps',
    //     name: 'Map & Data',
    //     MenuIcon: MapIcon,
    //     MenuToolTip: "Map & Data",
    //     permission: [
    //         Roles.SUPER_ADMIN,
    //         Roles.ADMIN,
    //         Roles.ACCOUNT_MANAGER
    //     ],
    // },
    // ],
    // secondaryDrawer: [
    //     {
    //         element: Launch,
    //         path: '/launch',
    //         name: 'secondary drawer Launch',
    //         MenuIcon: FlightTakeoffIcon,
    //         permission: [
    //             Roles.SUPER_ADMIN,
    //             Roles.ADMIN,
    //             Roles.ACCOUNT_MANAGER
    //         ]
    //     },
    // ],
    // },
    // {
    //     name: 'Cases',
    //     MenuIcon: CasesIcon,
    //     MenuToolTip: "Salesforce Cases",
    //     permission: [
    //         Roles.SUPER_ADMIN,
    //         Roles.ADMIN,
    //         Roles.SALES_MANAGER
    //     ],
    //     children: [
    //         {
    //             element: Salesforce,
    //             path: '/salesforce',
    //             name: 'Salesforce',
    //             MenuIcon: SettingsSystemDaydreamIcon,
    //             MenuToolTip: "Salesforce",
    //             permission: [
    //                 Roles.SUPER_ADMIN,
    //                 Roles.ADMIN,
    //                 Roles.ACCOUNT_MANAGER
    //             ],
    //         // },
    //     // ]
    // },
    // {
    //             element: NewCases,
    //             path: '/salesforce/newcases',
    //             // path: '/salesforce/case/sf/casebyaccount',
    //             name: 'New Cases',
    //             MenuIcon: CasesIcon,
    //             MenuToolTip: "Salesforce Cases",
    //             permission: [
    //                 Roles.SUPER_ADMIN,
    //                 Roles.ADMIN,
    //                 Roles.ACCOUNT_MANAGER
    //             ],
    //         // },
    //     // ]
    // },
    // {
    //             element: OpenCases,
    //             path: '/salesforce/opencases',
    //             name: 'Open Cases',
    //             MenuIcon: CasesIcon,
    //             MenuToolTip: "Salesforce Open Cases",
    //             permission: [
    //                 Roles.SUPER_ADMIN,
    // Roles.ADMIN,
    // Roles.ACCOUNT_MANAGER
    // ],
    // },
    // ]
    // },
    // {
    //             element: SalesforceToken,
    //             path: '/salesforce/token',
    //             name: 'SF Token Redirect Page',
    //             MenuIcon: Divider,
    //             MenuToolTip: "Will be hiding this",
    //             permission: [
    //                 Roles.SUPER_ADMIN,
    //                 Roles.ADMIN,
    //                 Roles.ACCOUNT_MANAGER
    //             ],
    //         // },
    //     // ]
    // },
    {
        element: LeadGenerator,
        name: 'Lead Generator',
        path: '/leadgenerator',
        MenuIcon: AutoGraphIcon,
        MenuToolTip: "Generate Sales leads",
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.SALES_MANAGER
        ],
        // children: [
        //     {
        //     	element: NationState,
        //     	path: '/nationstate',
        //     	name: 'Map & Data',
        //     	MenuIcon: MapIcon,
        //         MenuToolTip: "Sales tools",
        //     	permission: [
        //     		Roles.SUPER_ADMIN,
        //     		// Roles.ADMIN,
        //     		// Roles.ACCOUNT_MANAGER
        //     	],
        //     },
        // ]
    },
    {
        element: Room,
        path: '/room',
        name: 'Socket Debug',
        MenuIcon: SettingsInputCompositeIcon,
        MenuToolTip: "socket debug",
        permission: [
            Roles.SUPER_ADMIN,
            //         // Roles.ADMIN,
            //         // Roles.ACCOUNT_MANAGER
        ],
    },
    // {
    //     element: Admin,
    //     path: '/admin',
    //     name: 'Admin',
    //     MenuIcon: AdminPanelSettingsIcon,
    //     MenuToolTip: "Admin area",
    //     permission: [
    //         Roles.SUPER_ADMIN,
    //         // Roles.ADMIN
    //     ],
    // },
    {
        element: Users,
        path: '/admin/users',
        name: 'Users',
        MenuIcon: MapIcon,
        MenuToolTip: "Users",
        permission: [
            Roles.SUPER_ADMIN,
            // Roles.ADMIN,
            // Roles.ACCOUNT_MANAGER
        ],
    },
    {
        element: DatabaseSettings,
        path: '/admin/mongodb',
        name: 'MongoDB Configuration',
        MenuIcon: StorageIcon,
        MenuToolTip: "MongoDB Configuration",
        permission: [
            Roles.SUPER_ADMIN,
            // Roles.ADMIN,
            // Roles.SALES_MANAGER
        ],
    },
    {
        element: ClientSiteKeyConfiguration,
        path: '/admin/clientsiteconfiguration',
        name: 'Client Site Key Configuration',
        MenuIcon: VpnKeyIcon,
        MenuToolTip: "Client Site Key Configuration",
        permission: [
            Roles.SUPER_ADMIN,
            // Roles.ADMIN,
            // Roles.SALES_MANAGER
        ],
    },
    // {   element: Changelog,
    //     path: '/changelog',
    //     name: 'Changelog',
    //     MenuIcon: AssignmentIcon,
    //     MenuToolTip: "recent changelog",
    //     permission: [
    //         Roles.SUPER_ADMIN,
    //         Roles.ADMIN,
    //         Roles.ACCOUNT_MANAGER,
    //         Roles.SALES_MANAGER,
    //         Roles.SALES_IMC,
    //     ],
    // },
]
// ])

export default NavigationRoutePermissions